<template>
  <div id="apps_module">
      <div class="px-4 py-2 d-flex align-center">
      <div class="font-weight-bold primaryColor" id="apps_module_label">3rd Party Integrations</div>
      <v-progress-circular
            class="ml-2"
            indeterminate
            v-if="appsLoader"
            size="18"
            :width="2"
            color="blue"
          ></v-progress-circular>
    </div>
    <v-row class="ma-0 px-4">
       <v-slide-group v-model="infoType" class="pa-0" mandatory hide-arrows>
        <v-slide-item
          v-for="(item, i) in tabList"
          :key="i"
          v-slot="{ active, toggle }"
        >
          <v-btn
            depressed
            :color="active ? 'activeColor white--text' : 'unActiveColor black--text'"
            height="26"
            min-width="50"
            class="fsize12 rounded-sm text-center text-capitalize"
            :value="item.name"
            @click="toggle"
            :id="`${item.name}_menu`"
          >
            {{ item.name }}
            <span v-if="item.length != 0">&nbsp;({{item.length}})</span>
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-row>

    <v-divider class="mx-4 my-2"></v-divider>

    <div class="mb-4" v-if="vendorList.length > 0 && !this.appsLoader">
      <v-card class="mx-4" id="vendor_list_card">
        <v-sheet tile v-for="(n, index) in vendorList" :key="index" :id="`${index}_vendor_sheet`">
        <div class="row ma-0">
          <v-flex xs12 sm9 md9 lg9 xl9 class="pa-3">
            <label
              class="
                fsize16
                primaryColor
                font-weight-bold
                l-height-28
                ml-2
                pt-2
                pb-1
              " :id="`${index}_vendor_name`"
              >{{ n.vendorName }}</label
            >
            <div>
              <span class="fsize11 secondColor ml-2" :id="`${index}_vendor_developer`">by {{ n.developer }}</span>
            </div>
            <div>
              <p class="fsize13 secondColor ml-2" :id="`${index}_vendor_descript`">
                {{ n.description }}
              </p>
            </div>
          </v-flex>
          <v-flex xs12 sm3 md3 lg3 xl3 class="pa-3  d-flex align-center justify-center">
            <v-btn :id="`${index}_auth_revoke_btn`"
              :color="
                n.authorizationStatus == 0 ||
                n.authorizationStatus == 3 ||
                n.authorizationStatus == 4
                  ? 'primary'
                  : 'bg-red'
              "
              min-width="96"
              depressed
              class="text-capitalize  white--text fsize14 ma-0 bg-red rounded"
              @click="getInfo(n)"
            >
              {{
                n.authorizationStatus == 0 ||
                n.authorizationStatus == 3 ||
                n.authorizationStatus == 4
                  ? "Authorize"
                  : "Revoke"
              }}
            </v-btn>
          </v-flex>
        </div>
        <v-divider></v-divider>
        </v-sheet>
      </v-card>
    </div>
     <div class="d-flex flex-column justify-center align-center vh-70" v-if="vendorList.length == 0 && !this.appsLoader">
         <div> <img src="../assets/images/noData.svg" id="no_data_apps_img" alt="" class="w-300 h-170 mb-4"></div>
         <div id="no_data_apps_label"> NO APPS FOUND</div>
    </div>

    <v-row class="justify-center">
      <v-dialog v-model="authorizeDialog" width="400">
        <v-card height="317" id="apps_authorize_card">
          
             <div class="fsize14 cardHead d-flex align-center justify-center" id="auth_name">
                Authorize {{ this.vendorName }}
              </div>

          <div class="primaryColor cardBody ml-4 mt-8" id="authorize_info">
             <div class="text-center fsize14 text-center">
                Permission required by the app
              </div>
              <ul class="fsize14 ml-4 pt-4 mb-8 primaryColor">
                <li>Access holding and positions portfolio</li>
                <li>Place, modify, and cancel orders</li>
                <li>View your account balance and margins</li>
                <li>View your profile details</li>
              </ul>
          </div>
          <v-card-actions class="pa-3 pr-3">
            <v-spacer></v-spacer>

            <v-btn id="auth_agree_btn"
            color="primary"
              depressed
              class="rounded white--text text-capitalize btn32-clr-b"
              @click="conformAuthorize()"
            >
              Agree
            </v-btn>
                <v-btn id="auth_cancel_btn"
                min-width="70px"
              depressed
              outlined
              class="rounded text-capitalize"
              @click="$store.commit('apps/setAuthorizeDialog' , false)"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    vendorId:'',
    vendorName:'',
    infoType: "Apps",
  }),
  methods: {
    revokeApp(value){
      var jsonObject = {
        vendorId: value["vendorId"],
      };
      this.$store.dispatch('apps/getRevokeVendor', jsonObject)
    },
    getInfo(val) {
       var isAuthorize =  val.authorizationStatus == 0 || val.authorizationStatus == 3 || val.authorizationStatus == 4  ? true : false
    
    this.vendorName = val["vendorName"];
      this.vendorId = val["vendorId"];
      isAuthorize ? this.$store.commit('apps/setAuthorizeDialog' , true) : this.revokeApp(val)
    },
    conformAuthorize(){
       var jsonObj = {
        vendorId: this.vendorId,
      };
      this.$store.dispatch("apps/setAuthorize" , jsonObj)
    }
  },
  computed: {
    ...mapState("apps", ["vendorList", "authorizeVendor","revokeVendor" , 'tabList', 'authorizeDialog', 'appsLoader']),
  },

   mounted() {
     this.$store.dispatch("apps/getVendorList");
  },
};
</script>